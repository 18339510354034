import React, {PureComponent} from 'react';
import './TestButtonSection.scss';
import butterfly from './pic/butterfly.png'
import butterflyMobile from './pic/butterfly-mobile.png'
import {Link} from "react-scroll";
import Interweave from 'interweave';
import {inject, observer} from "mobx-react";
import {Parallax} from 'react-scroll-parallax';

@inject('Store')
@observer
class TestButtonSection extends PureComponent {

	render() {
		const {Store} = this.props;
		return (
			<section className="test_button_section">
				<h3><Interweave content={Store.content.zagolovok_v_bloke_testa}/></h3>
				<Link to="test">Пройти тест</Link>
				<div className="butterfly_bg">
					<Parallax className="custom-class" y={[20, 0]} tagOuter="figure">
						<picture>
                            <source srcSet={butterflyMobile} media="(max-width: 400px)" />
                            <img width={1171} height={383} src={butterfly} loading="lazy" alt="Пройдите тест и определите" />
                        </picture>
					</Parallax>
				</div>
			</section>
		);
	}
}

export default TestButtonSection;
