import React, {PureComponent} from 'react';
import {Link} from "react-router-dom";
import './Footer.scss';
import {inject, observer} from "mobx-react";
import Interweave from 'interweave';
import CookieConsent from "react-cookie-consent";

@inject('Store')
@observer
class Footer extends PureComponent {

	render() {
		const {Store} = this.props;
		return (
			<footer>
				<div className="footer_block_logo">
					{Store.footer.zagolovok_pered_logotipom}
					<img width={339} height={55} src={Store.footer.logotip_v_futere} alt="Берлин-Хеми/А.Менарини"/>
				</div>
				<div className="footer_block_content"><Interweave content={Store.footer.spisok} /></div>
				<CookieConsent
						location="bottom"
						buttonText="Принять"
						cookieName="lThyroxinCookie"
						style={{ background: "#FBD2D8", color: "#272727" }}
						buttonStyle={{ color: "#922358", fontSize: "20px", background: "#ffffff", fontWeight: "bold", borderRadius: "76px" }}
						expires={7}
					>Мы используем файлы cookie, чтобы улучшить работу сайта (см. подробнее в нашей <Link to="/privacy">Политике конфиденциальности</Link> и <Link to="/terms">Условиях использования</Link>). Продолжая использовать сайт, вы соглашаетесь с этим.</CookieConsent>
			</footer>
		);
	}
}

export default Footer;
