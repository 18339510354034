import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import Interweave from 'interweave';
import Footer from "../Footer";
import './Privacy.scss';

@inject('Store')
@observer
class Privacy extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.GET('pages/9').then(r => Store.content = r);
		Store.GET('pages/41').then(r => Store.footer = r);
		Store.GET('pages/313').then(r => Store.privacy = r);
		Store.GET('pages/319').then(r => Store.terms = r);
	}

	render() {
		const {Store} = this.props;
		return (
			Store.content ? (
				<Fragment>
					<div className="privacy">
						<div className="privacy-logo">
							<Link to="/">Гипотиреоз</Link>
						</div>
						<div className="privacy-hr"></div>
						{Store.privacy ? <div className='privacy-content'><Interweave content={Store.privacy.razmetka} /></div> : null}
						{Store.footer ? <Footer/> : null}
					</div>
				</Fragment>
			) : null
		);
	}
}

export default Privacy;