import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class IconBoxItem extends PureComponent {
	render() {
		const style = {
			backgroundImage: `url(${this.props.overlay})`,
		}
		return (
			<div className={`icon_box_item ${this.props.className}`}>
				<div className="icon_box_item_pic">
					<img width={this.props.width} height={this.props.height} src={this.props.pic} alt={this.props.content}/>
					<div className="overlay" style={style}></div>
				</div>
				<div className="icon_box_item_content">{this.props.content}</div>
			</div>
		);
	}
}

IconBoxItem.propTypes = {
	pic: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default IconBoxItem;
